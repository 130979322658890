<template>
  <v-app id="inspire">
    <v-navigation-drawer id="left_menu" v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app :temporary="menutemp">
      <v-list dense>
        <template v-for="item in items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group v-else-if="item.children" :key="item.text" v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']" append-icon="">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(child, i) in item.children" :key="i" @click="goView(child.view)">
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.text" @click="goView(item.view)">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="blue darken-3" dark>
      <v-app-bar-nav-icon @click.stop="setDrawer" />
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">
          <a href="#" v-on:click="$router.replace({ name: 'Home' })" style="color:white"><img src="/images/siged_n.png"
              alt="siged"></a>
        </span>
      </v-toolbar-title>
      <!--
      <v-text-field
        flat
        solo-inverted
        hide-details
        prepend-inner-icon="mdi-magnify"
        label="Buscar"
        class="hidden-sm-and-down"
      />
      -->
      <v-spacer />
      <!--
      <v-btn icon>
        <v-icon>mdi-apps</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-bell</v-icon>
      </v-btn>
      -->
      <span class="hidden-sm-and-down">
        <div class="text-right" style="width:120px; height:30px;">
          <a href="#" v-on:click="actualizarCompania()">
            <img v-bind:src="$store.state.logo"
              style="background-color:white; max-width:100%; max-height:100%; padding: 1px;" />
          </a>
        </div>
      </span>
      <v-menu v-model="menupop" :close-on-content-click="true" :nudge-width="200" offset-x>
        <template v-slot:activator="{ on }">
          <v-btn icon large v-on="on">
            <v-avatar size="32px" item>
              <v-icon>mdi-account-circle</v-icon>
            </v-avatar>
          </v-btn>
          <span>{{ $store.state.userdata.name }}</span>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <!-- <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John"> -->
                <v-icon>mdi-face-profile</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ $store.state.userdata.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ $store.state.companydata.cmp_nmbre }}</v-list-item-subtitle>
              </v-list-item-content>

            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item @click="profile">
              <v-list-item-icon>
                <v-icon>mdi-account-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Perfil</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="logout(false)">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Cerrar sesión</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <!--
    <v-btn
      bottom
      color="pink"
      dark
      fab
      fixed
      right
      @click="dialog = !dialog"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    -->

  </v-app>
</template>

<script>
const TIMEOUT = 1;

/*
import { 
  VApp, VAppBar, VAppBarNavIcon, VAvatar, VBtn, VCard, VCol, VContainer, 
  VDivider, VIcon, VList, VListGroup, VListItem, VListItemAction, VListItemAvatar, 
  VListItemContent, VListItemIcon, VListItemSubtitle, VListItemTitle, VMain, VMenu, 
  VNavigationDrawer, VRow, VSpacer, VSubheader, VToolbarTitle
  } from 'vuetify/lib';
  */

//import toastr from 'toastr';
import Bowser from "bowser";

export default {
  props: {
    source: String
  },
  components: {
    /*
    VApp, VAppBar, VAppBarNavIcon, VAvatar, VBtn, VCard, VCol, VContainer, 
    VDivider, VIcon, VList, VListGroup, VListItem, VListItemAction, VListItemAvatar, 
    VListItemContent, VListItemIcon, VListItemSubtitle, VListItemTitle, VMain, VMenu, 
    VNavigationDrawer, VRow, VSpacer, VSubheader, VToolbarTitle
    */
  },
  data: () => ({
    dialog: false,
    drawer: null,
    items: [],
    item: {},
    message: "",
    res: {},
    menu: {},
    menus: [],
    menupfs: [],
    fav: true,
    menupop: false,
    messagepop: false,
    hints: true,
    logo: "",
    menutemp: false
  }),
  computed: {
    company() {
      return this.$store.state.company;
    },
    companydata() {
      return this.$store.state.companydata;
    },
    user() {
      return this.$store.state.user;
    },
    userdata() {
      return this.$store.state.userdata;
    },
  },
  beforeCreate: function () {
    /*
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
      */
  },
  watch: {
    drawer() {
      this.$store.commit("setDrawer", this.drawer);
    },
  },
  created: function () {
    this.$store.state.group_switch = true;
    
    this.idleLogout();

    window.addEventListener('beforeunload', this.beforeWindowUnload);

    const browser = Bowser.getParser(window.navigator.userAgent);
    const isAntiqueChromeBrowser = browser.satisfies({
      chrome: "<50"
    });
    this.menutemp = isAntiqueChromeBrowser;

    if (
      sessionStorage.getItem("jwtToken") != null &&
      this.$store.state.user != ""
    ) {
      this.$store.commit("setIsAuth", true);
      this.fetchMenupf();

      if (this.$route.params.id) {
        // VALIDAR QUE USUARIO ESTÉ EN EL SEGUIMIENTO
        let uri = '/docs/usuario_seguimiento';
        this.axios.post(uri, { id: this.$route.params.id, cmp_id: this.$store.state.company, usu_id: this.$store.state.user })
          .then((response) => {
            if (response.data.existe) {
              this.$router.replace({ name: 'CreateDoc', params: { id: this.$route.params.id, add: false, search: {} } });
            } else {
              alert('No se puede mostrar el documento porque se le ha dejado de compartir.');
              this.$router.replace({ name: "Home" });
            }
          })
          .catch((err) => {
            alert('Error al consultar el documento. ' + err);
            this.$router.replace({ name: "Home" });
          });
      } else {
        this.$router.replace({ name: "Home" });
      }
    } else {
      this.$store.commit("setIsAuth", false);
      this.$router.replace({ name: "Signin" });
    }
  },
  mounted: function () {
    // From testing, without a brief timeout, it won't work.
    if (this.$route.hash) {
      setTimeout(() => this.scrollTo(this.$route.hash), TIMEOUT);
    }

    var path = "./../repos/" + String(this.$store.state.company) + "/logos";
    if (!this.$store.state.logo) {
      this.downloadFile(path, String(this.$store.state.companydata.cmp_logo));
    }
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },
  /*
  beforeRouteLeave (to, from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    
    if (this.confirmLeave()){
      next();
    }
    
  },  
  */
  methods: {
    confirmLeave() {
      return window.confirm('Desea realmente salir?');
    },
    updateLog(callback) {
      let uri = '/logs/update';
      this.axios.post(uri, { id: String(this.$store.state.sessionId), log_tiempo_actividad: 0 })
        .then((response) => {
          console.log(response);
          return callback();
        })
        .catch(err => {
          console.log(err);
          return callback();
        })
    },
    beforeWindowUnload(e) {
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = '';
      //this.logout(false);      
      this.updateLog(() => {
        if (!this.$store.state.company || !this.$store.state.user) {
          this.$router.replace({ name: "Signin" });
        }
      });
    },
    idleLogout() {
      var t;

      const yourFunction = () => {
        //this.logout(true);
        this.$store.commit("setIsAuth", false);
        sessionStorage.removeItem("jwtToken");
        this.$store.commit("setUserdata", {});
        this.$store.commit("setUser", undefined);
        this.message = "";
        this.updateLog(() => {
          this.$router.replace({ name: "IdleLogout" });
        });
      }

      const resetTimer = () => {
        clearTimeout(t);
        t = setTimeout(yourFunction, 60 * 60 * 1000);
      }

      var events = ['load', 'mousemove', 'mousedown', 'touchstart', 'click', 'keypress', 'scroll'];
      events.forEach((name) => {
        window.addEventListener(name, resetTimer);
      });
    },
    scrollTo: function (hashtag) {
      setTimeout(() => {
        location.href = hashtag;
      }, TIMEOUT);
    },
    increment() {
      this.$store.commit("increment");
    },
    decrement() {
      this.$store.commit("decrement");
    },
    getCompaniaReclamaciones(callback) {
      var p = { cmp_id: String(this.$store.state.company) };
      let uri = '/cmps/activar-reclamaciones';
      this.axios.post(uri, p)
        .then((response) => {
          return callback(response.data.cmp_activar_reclamaciones);
        })
        .catch((err) => {
          return callback(false);
        });
    },
    getCompaniaPqrs(callback) {
      var p = { cmp_id: String(this.$store.state.company) };
      let uri = '/cmps/activar-pqrs';
      this.axios.post(uri, p)
        .then((response) => {
          return callback(response.data.cmp_activar_pqrs);
        })
        .catch((err) => {
          return callback(false);
        });
    },
    getCompaniaEmail(callback) {
      var p = { cmp_id: String(this.$store.state.company) };
      let uri = '/cmps/activar-email';
      this.axios.post(uri, p)
        .then((response) => {
          return callback(response.data.cmp_activar_email);
        })
        .catch((err) => {
          return callback(false);
        });
    },
    fetchMenupf() {
      let uri = "/menupfs/user";
      this.axios
        .post(uri, {
          tus_id: this.$store.state.userdata.type,
        })
        .then((response) => {
          this.menupfs = response.data;

          this.getCompaniaReclamaciones((value) => {
            this.$store.state.reclamaciones = value;
            
            this.getCompaniaPqrs((value) => {
              this.$store.state.pqrs = value;

              this.getCompaniaEmail((value) => {
                this.$store.state.email = value;
                
                this.menuItems();
              });              
            });            
          });

        })
        .catch((err) => {
          console.log(err);
        });
    },
    menuItems() {
      this.items = [];

      var itemp = {};
      if (this.showMenu("documentos"))
        this.items.push({
          icon: "content_copy",
          text: "Documentos",
          view: "DisplayDoc",
        });

      /*
      if (this.showMenu("expedientes"))
        this.items.push({
          icon: "folder",
          text: "Expedientes",
          view: "fileManager",
        });
      */
     
      if (this.showMenu("correo-electronico") && this.$store.state.email) {
        this.items.push({
          icon: "email",
          text: "Correo electronico",
          view: "MessageBox",
        });

        this.$store.state.email = true;
      } else {
        this.$store.state.email = false;
      }

      if (this.showMenu("reclamaciones") && this.$store.state.reclamaciones) {
        itemp = {
          icon: "keyboard_arrow_up",
          "icon-alt": "keyboard_arrow_down",
          text: "Reclamaciones",
          model: false,
          children: [],
        };

        if (this.showMenu("reclamaciones-lista")) {
          itemp.children.push({
            text: "Lista",
            view: "DisplayFormatoReclamo",
          });
        }
        if (this.showMenu("reclamaciones-reporte")) {
          itemp.children.push({
            text: "Reporte",
            view: "FormReclamos",
          });
        }

        if (this.showMenu("reclamaciones-reporte-sspd")) {
          itemp.children.push({
            text: "Reporte Sspd",
            view: "FormReclamosSspd",
          });
        }

        this.items.push(itemp);

        this.$store.state.reclamaciones = true;
      } else {
        this.$store.state.reclamaciones = false;
      }

      if (this.showMenu("pqrs") && this.$store.state.pqrs) {
        itemp = {
          icon: "keyboard_arrow_up",
          "icon-alt": "keyboard_arrow_down",
          text: "Pqrs Contraloría",
          model: false,
          children: [],
        };

        if (this.showMenu("pqrs-lista")) {
          itemp.children.push({
            text: "Lista",
            view: "DisplayFormatoPqrs",
          });
        }

        if (this.showMenu("pqrs-reporte-contraloria")) {
          itemp.children.push({
            text: "Reporte Contraloría",
            view: "FormPqrsContraloria",
          });
        }

        this.items.push(itemp);

        this.$store.state.pqrs = true;
      } else {
        this.$store.state.pqrs = false;
      }

      if (this.showMenu("reportes")) {
        itemp = {
          icon: "keyboard_arrow_up",
          "icon-alt": "keyboard_arrow_down",
          text: "Reportes",
          model: false,
          children: [],
        };

        if (this.showMenu("reportes-controlentrega")) {
          itemp.children.push({
            text: "Control de entrega",
            view: "FormControlEntrega",
          });
        }
        if (this.showMenu("reportes-docsradicados")) {
          itemp.children.push({
            text: "Correspondencia radicada",
            view: "FormDocsRadicados",
          });
        }
        if (this.showMenu("reportes-pqrs")) {
          itemp.children.push({
            text: "Solicitudes realizadas",
            view: "FormPqrs",
          });
        }
        if (this.showMenu("reportes-tiemposrespuesta")) {
          itemp.children.push({
            text: "Solicitudes respondidas",
            view: "FormTiemposRespuesta",
          });
        }
        if (this.showMenu("reportes-pendienterespuesta")) {
          itemp.children.push({
            text: "Solicitudes pendientes de respuesta",
            view: "FormPendienteRespuesta",
          });
        }
        if (this.showMenu("reportes-docsradusuario")) {
          itemp.children.push({
            text: "Documentos creados por usuario",
            view: "FormDocsRadUsuario",
          });
        }
        if (this.showMenu("reportes-segdocs")) {
          itemp.children.push({
            text: "Usuario con seguimientos",
            view: "FormSegDocs",
          });
        }
        if (this.showMenu("reportes-solicitudes-asignadas")) {
          itemp.children.push({
            text: "Solicitudes asignadas",
            view: "FormSolicitudesAsignadas",
          });
        }
        if (this.showMenu("reportes-sinadjuntos")) {
          itemp.children.push({
            text: "Doc. sin archivos adjuntos",
            view: "FormSinAdjuntos",
          });
        }
        if (this.showMenu("reportes-estadisticaradicada")) {
          itemp.children.push({
            text: "Estadística de correspondencia radicada",
            view: "FormEstadisticaRadicada",
          });
        }
        if (this.showMenu("reportes-estadisticaradusuario")) {
          itemp.children.push({
            text: "Estadística de documentos creados por usuarios",
            view: "FormEstadisticaRadUsuario",
          });
        }
        if (this.showMenu("reportes-estadisticapqrs")) {
          itemp.children.push({
            text: "Estadística de Solicitudes",
            view: "FormEstadisticaPqrs",
          });
        }
        if (this.showMenu("reportes-estadisticapqrstotal")) {
          itemp.children.push({
            text: "Estadística de Solicitudes Total",
            view: "FormEstadisticaPqrsTotal",
          });
        }
        if (this.showMenu("reportes-estadistica-docstramitados")) {
          itemp.children.push({
            text: "Estadística de Documentos Tramitados",
            view: "FormEstadisticaDocsTramitados",
          });
        }
        if (this.showMenu("reportes-inventariodocs")) {
          itemp.children.push({
            text: "Inventario de documentos",
            view: "FormInventarioDocs",
          });
        }
        if (this.showMenu("reportes-fuid")) {
          itemp.children.push({
            text: "Formato FUID",
            view: "FormFuid",
          });
        }
        if (this.showMenu("reportes-etiquetacaja")) {
          itemp.children.push({
            text: "Etiqueta de Caja",
            view: "FormEtiquetaCaja",
          });
        }
        if (this.showMenu("reportes-contenidocaja")) {
          itemp.children.push({
            text: "Contenido de Caja",
            view: "FormContenidoCaja",
          });
        }
        if (this.showMenu("reportes-contenidolegajo")) {
          itemp.children.push({
            text: "Contenido de Legajo",
            view: "FormContenidoLegajo",
          });
        }
        if (this.showMenu("reportes-docs-sin-archivar")) {
          itemp.children.push({
            text: "Documentos sin archivar",
            view: "FormDocsSinArchivar",
          });
        }
        if (this.showMenu("reportes-adjuntos-faltantes")) {
          itemp.children.push({
            text: "Adjuntos faltantes",
            view: "FormAdjuntosFaltantes",
          });
        }
        if (this.showMenu("reportes-transferencias")) {
          itemp.children.push({
            text: "Transferencias de documentos",
            view: "FormTransferencias",
          });
        }
        if (this.showMenu("reportes-tamanorepos")) {
          itemp.children.push({
            text: "Tamaño de Repositorio",
            view: "FormTamanoRepos",
          });
        }
        if (this.showMenu("reportes-logs-docs")) {
          itemp.children.push({
            text: "Reporte documentos cargados",
            view: "LogsDcmnt",
          });
        }

        if (this.showMenu("reportes-attached-files")) {
          itemp.children.push({
            text: "Reporte de archivos adjuntos",
            view: "AttachedFiles",
          });
        }

        this.items.push(itemp);
      }

      if (this.showMenu("archivo")) {
        itemp = {
          icon: "keyboard_arrow_up",
          "icon-alt": "keyboard_arrow_down",
          text: "Archivo",
          model: false,
          children: [],
        };

        if (this.showMenu("archivo-prestamos")) {
          itemp.children.push({
            text: "Préstamos",
            view: "DisplayPrestamo",
          });
          this.$store.commit('setPrestamos', true);
        } else {
          this.$store.commit('setPrestamos', false);
        }

        this.items.push(itemp);
      }

      if (this.showMenu("baseapp")) {
        itemp = {
          icon: "keyboard_arrow_up",
          "icon-alt": "keyboard_arrow_down",
          text: "Aplicación",
          model: false,
          children: [],
        };
        if (this.showMenu("baseapp-companias")) {
          itemp.children.push({
            text: "Compañías",
            view: "DisplayCompania",
          });
        }
        if (this.showMenu("baseapp-ciudades")) {
          itemp.children.push({
            text: "Ciudades",
            view: "DisplayCiudad",
          });
        }
        if (this.showMenu("baseapp-menus")) {
          itemp.children.push({
            text: "Menú",
            view: "DisplayMenu",
          });
        }
        if (this.showMenu("baseapp-menupfs")) {
          itemp.children.push({
            text: "Menú por perfil",
            view: "DisplayMenupf",
          });
        }
        if (this.showMenu("baseapp-tiposusuario")) {
          itemp.children.push({
            text: "Tipos de usuario",
            view: "DisplayTpousu",
          });
        }
        if (this.showMenu("baseapp-usuarios")) {
          itemp.children.push({
            //icon: "mdi-account-multiple",
            text: "Usuarios - Global",
            view: "DisplayUsuarioGlobal",
          });
        }
        if (this.showMenu("baseapp-logs")) {
          itemp.children.push({
            text: "Registro de sesiones",
            view: "DisplayLogGlobal",
          });
        }
        if (this.showMenu("baseapp-uxc")) {
          itemp.children.push({
            text: "Usuarios por compañía",
            view: "DisplayUsuxcmp",
          });
        }
        if (this.showMenu("baseapp-contadores")) {
          itemp.children.push({
            text: "Contadores",
            view: "DisplayCounter",
          });
        }
        if (this.showMenu("baseapp-migrar")) {
          itemp.children.push({
            text: "Migrar datos",
            view: "MigrarDatos",
          });
        }
        if (this.showMenu("baseapp-export")) {
          itemp.children.push({
            text: "Exportar datos",
            view: "Export",
          });
        }
        if (this.showMenu("baseapp-docnoentablas")) {
          itemp.children.push({
            text: "Doc. no relacionados con tablas",
            view: "DisplayDocNotInTablas",
          });
        }
        if (this.showMenu("baseapp-email")) {
          itemp.children.push({
            text: "Correo electrónico",
            view: "SettingEmail",
          });
        }
        if (this.showMenu("baseapp-dianols")) {
          itemp.children.push({
            text: "Días no laborales - Global",
            view: "DisplayDianolGlobal",
          });
        }

        if (this.showMenu("baseapp-fixadjuncts")) {
          itemp.children.push({
            text: "Ajuste archivos adjuntos",
            view: "FixSizePagesAdjuncts",
          });
        }

        if (this.showMenu("baseapp-fixocr")) {
          itemp.children.push({
            text: "Ajuste OCR",
            view: "FixOcr",
          });
        }

        this.items.push(itemp);
      }

      // Usuarios
      if (this.showMenu("usuarios")) {
        itemp = {
          icon: "keyboard_arrow_up",
          "icon-alt": "keyboard_arrow_down",
          text: "Usuarios",
          model: false,
          children: [],
        };
        if (this.showMenu("usuarios-usuarios")) {
          itemp.children.push({
            //icon: "mdi-account-multiple",
            text: "Lista de Usuarios",
            view: "DisplayUsuario",
          });
        }
        if (this.showMenu("usuarios-uxr")) {
          itemp.children.push({
            text: "Permisos por Dependencia y Serie",
            view: "DisplayUsuxrad",
          });
        }
        if (this.showMenu("usuarios-sala")) {
          itemp.children.push({
            text: "Permisos por Sala",
            view: "DisplayUsuxsal",
          });
        }
        if (this.showMenu("usuarios-logs")) {
          itemp.children.push({
            text: "Registro de sesiones",
            view: "DisplayLog",
          });
        }
        if (this.showMenu("usuarios-actividad")) {
          itemp.children.push({
            text: "Actividad",
            view: "DisplayActivity",
          });
        }

        this.items.push(itemp);
      }

      // Maestros
      if (this.showMenu("base")) {
        itemp = {
          icon: "keyboard_arrow_up",
          "icon-alt": "keyboard_arrow_down",
          text: "Maestros",
          model: false,
          children: [],
        };

        if (this.showMenu("base-dependencias")) {
          itemp.children.push({
            text: "Dependencias",
            view: "DisplayDependencia",
          });
        }
        if (this.showMenu("base-entidades")) {
          itemp.children.push({
            text: "Entidades",
            view: "DisplayEntidad",
          });
        }
        if (this.showMenu("base-estados")) {
          itemp.children.push({
            text: "Estados",
            view: "DisplayEstado",
          });
        }
        if (this.showMenu("base-indices")) {
          itemp.children.push({
            text: "Índices",
            view: "DisplayIndice",
          });
        }
        if (this.showMenu("base-mediosrespuesta")) {
          itemp.children.push({
            text: "Medios de respuesta",
            view: "DisplayMedioresp",
          });
        }
        if (this.showMenu("base-plantillas")) {
          itemp.children.push({
            text: "Plantillas",
            view: "DisplayPlantilla",
          });
        }
        if (this.showMenu("base-procesos")) {
          itemp.children.push({
            text: "Procesos",
            view: "DisplayProceso",
          });
        }
        if (this.showMenu("base-salas")) {
          itemp.children.push({
            text: "Salas",
            view: "DisplaySala",
          });
        }
        if (this.showMenu("base-series")) {
          itemp.children.push({
            text: "Series",
            view: "DisplaySerie",
          });
        }
        if (this.showMenu("base-tablas")) {
          itemp.children.push({
            text: "Tablas",
            view: "DisplayTabla",
          });
        }
        if (this.showMenu("base-versionestabla")) {
          itemp.children.push({
            text: "Versiones de Tabla",
            view: "DisplayTablaVersion",
          });
        }
        if (this.showMenu("base-tiposarchivo")) {
          itemp.children.push({
            text: "Tipos de Archivo",
            view: "DisplayTpoarch",
          });
        }
        if (this.showMenu("base-clasesdocumento")) {
          itemp.children.push({
            text: "Clases de Documento",
            view: "DisplayTpocor",
          });
        }
        if (this.showMenu("base-tiposdocumento")) {
          itemp.children.push({
            text: "Tipos de Documento",
            view: "DisplayTpodoc",
          });
        }
        if (this.showMenu("base-tiposid")) {
          itemp.children.push({
            text: "Tipos de Identificación",
            view: "DisplayTpoid",
          });
        }
        if (this.showMenu("base-tipossolicitante")) {
          itemp.children.push({
            text: "Tipos de Solicitante",
            view: "DisplayTposolic",
          });
        }
        if (this.showMenu("base-tipostabla")) {
          itemp.children.push({
            text: "Tipos de Tabla",
            view: "DisplayTpotabla",
          });
        }
        if (this.showMenu("base-tipospqrs")) {
          itemp.children.push({
            text: "Tipos de Pqrs",
            view: "DisplayTpopqrs",
          });
        }
        if (this.showMenu("base-unidadesconservacion")) {
          itemp.children.push({
            text: "Unidades de conservación",
            view: "DisplayUconserv",
          });
        }
        if (this.showMenu("base-menupfs")) {
          itemp.children.push({
            text: "Menú por perfil",
            view: "DisplayMenupf",
          });
        }
        if (this.showMenu("base-tiposusuario")) {
          itemp.children.push({
            text: "Tipos de Usuario",
            view: "DisplayTpousu",
          });
        }
        if (this.showMenu("base-usuarios")) {
          itemp.children.push({
            text: "Usuarios",
            view: "DisplayUsuario",
          });
        }

        /** RECLAMACIONES */

        if (this.showMenu("base-servicios")) {
          itemp.children.push({
            text: "Servicios",
            view: "DisplayServicio",
          });
        }

        if (this.showMenu("base-tipotramites")) {
          itemp.children.push({
            text: "Tipo de trámites",
            view: "DisplayTipoTramite",
          });
        }

        if (this.showMenu("base-tiporespuestas")) {
          itemp.children.push({
            text: "Tipo de respuestas",
            view: "DisplayTipoRespuesta",
          });
        }

        if (this.showMenu("base-tipocausales")) {
          itemp.children.push({
            text: "Tipo de causales",
            view: "DisplayTipoCausal",
          });
        }


        if (this.showMenu("base-causales")) {
          itemp.children.push({
            text: "Causales",
            view: "DisplayCausal",
          });
        }

        if (this.showMenu("base-tiponotificaciones")) {
          itemp.children.push({
            text: "Tipo de notificaciones",
            view: "DisplayTipoNotificacion",
          });
        }

        /** END RECLAMACIONES */

        this.items.push(itemp);
      }

      if (this.showMenu("configuracion")) {
        itemp = {
          icon: "keyboard_arrow_up",
          "icon-alt": "keyboard_arrow_down",
          text: "Configuración",
          model: false,
          children: [],
        };

        if (this.showMenu("configuracion-compania")) {
          itemp.children.push({
            icon: "settings",
            text: "Empresa",
            view: "Compania",
          });
        }

        if (this.showMenu("configuracion-pqrs")) {
          itemp.children.push({
            icon: "settings",
            text: "Pqrs",
            view: "SettingPqrs",
          });
        }

        if (this.showMenu("configuracion-inbox")) {
          itemp.children.push({
            icon: "settings",
            text: "Radicación de correo electrónico",
            view: "DisplayInbox",
          });
        }

        if (this.showMenu("configuracion-inbox-user")) {
          itemp.children.push({
            icon: "settings",
            text: "Correo electrónico conectado",
            view: "DisplayInboxUser",
          });
        }

        if (this.showMenu("configuracion-firmas")) {
          itemp.children.push({
            icon: "settings",
            text: "Firmas",
            view: "DisplayFirma",
          });
        }

        if (this.showMenu("configuracion-dianols")) {
          itemp.children.push({
            icon: "settings",
            text: "Días no laborales",
            view: "DisplayDianol",
          });
        }

        if (this.showMenu("configuracion-auth-federado")) {
          itemp.children.push({
            icon: "settings",
            text: "Autenticación Federada",
            view: "AuthFederado",
          });
        }

        if (this.showMenu("configuracion-opciones")) {
          itemp.children.push({
            icon: "settings",
            text: "Opciones",
            view: "SettingOptions",
          });
        }

        if (this.showMenu("configuracion-backup")) {
          itemp.children.push({
            icon: "settings",
            text: "Copia de seguridad",
            view: "SettingBackup",
          });
        }

        this.items.push(itemp);
      }

      if (this.showMenu("ayuda")) {
        itemp = {
          icon: "keyboard_arrow_up",
          "icon-alt": "keyboard_arrow_down",
          text: "Ayuda",
          model: false,
          children: [],
        };

        if (this.showMenu("ayuda-doc")) {
          itemp.children.push({
            icon: "help",
            text: "Documentacion",
            view: "Help",
          });
        }

        if (this.showMenu("ayuda-support")) {
          itemp.children.push({
            icon: "help",
            text: "Soporte técnico",
            view: "Support",
          });
        }

        if (this.showMenu("ayuda-acerca")) {
          itemp.children.push({
            icon: "help",
            text: "Acerca de Siged",
            view: "About",
          });
        }

        this.items.push(itemp);
      }
    },
    showMenu(menu) {
      for (var i = 0; i < this.menupfs.length; i++) {
        if (menu == this.menupfs[i]) {
          return true;
        }
      }
      return false;
    },
    goView(v) {
      this.$router.replace({ name: v });
    },
    logout(idle) {
      this.$store.commit("setIsAuth", false);
      sessionStorage.removeItem("jwtToken");
      this.$store.commit("setUserdata", {});
      this.$store.commit("setUser", undefined);
      this.message = "";
      this.updateLog(async () => {
        if (idle) {
          this.$router.replace({ name: "IdleLogout" });
        } else {
          let uri = "/passaport/logout"
          await this.axios({ url: uri, method: "POST" })
          this.$router.replace({ name: "Signin" });
        }
      });
    },
    profile() {
      this.$router.replace({ name: "Profile" });
    },
    setDrawer() {
      this.drawer = !this.drawer;
      this.$store.commit("setDrawer", this.drawer);
    },
    downloadFile(path, filename) {
      this.axios({
        url: "/api/files/download",
        method: "POST",
        data: { path: path, filename: filename },
        responseType: "blob", // important
      }).then((response) => {
        this.$store.commit("setLogo", window.URL.createObjectURL(new Blob([response.data])));
      }).catch((err) => {
        console.log(JSON.stringify(err));
        this.$store.commit("setLogo", "/images/logo_not.png");
      });
    },
    actualizarCompania() {
      if (this.showMenu("configuracion")) {
        if (this.showMenu("configuracion-compania")) {
          this.$router.push({ name: "Compania" });
        }
      }
    },
  }, //END METHODS
};
</script>
<style>
.warning {
  color: #ff0000;
}

.fade-enter-active .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter .fade-leave-active {
  transition: 0;
}
</style>
